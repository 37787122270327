.top-nav {
    background-color: #202124;

    .nav-wrapper {
        padding-top: 5px;
        padding-bottom: 5px;

        .content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .contact-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;

                .link {
                    text-decoration: none;

                    .link-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .link-text {
                            font-size: 11px;
                            font-weight: 400;
                            font-family: Google Sans Text, Arial, Helvetica, sans-serif;
                            letter-spacing: 0.00938em;
                            line-height: 1.5;
                            color: #fff;
                            margin: 0;
                        }
                    }
                }
            }

            .media-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
            }
        }
    }

    @media (max-width: 991.98px) {
        display: none;
    }
}

.MuiDrawer-paper {
    background-color: #fff !important;

    .logo {
        padding-top: 12px;
        padding-bottom: 12px;
        z-index: 1200;
        box-shadow: 1px 2px 3px rgb(0 0 0 / 1%);

        .navbar-brand {
            display: flex;
            align-items: center;
            gap: 7px;
            padding-left: 16px;

            .brand-text {
                color: transparent;
                font: 400 14px/20px Google Sans Text,Arial,Helvetica,sans-serif;
                font-size: 8.925px;
                letter-spacing: .3px;
                font-weight: 500;
                white-space: nowrap;
                text-transform: uppercase;
                line-height: 1.4;
                background-image: linear-gradient(90deg, #d09636 0%, #d9ab5a 51%, #dbb97b 100%);
                background-clip: text;
            }
        }
    }

    .menu {
        background-color: #f5f5f5;
    }

    .media {
        background-color: #fff;
    }

    svg {
        filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(327deg) brightness(114%) contrast(114%);
        opacity: 0.75;
        user-select: none;
    }

    .list-item {
        padding: 0;

        .nav-link,
        .link {
            flex: 1 1 auto;
            min-width: 0px;
            padding: 0;
            line-height: 1.5;
            display: block;
            text-decoration: none;

            .list-item-icon {
                min-width: 45px;

                svg {
                    filter: invert(22%) sepia(2%) saturate(127%) hue-rotate(30deg) brightness(93%) contrast(89%);
                }
            }

            .list-item-text {
                span {
                    color: rgba(0, 0, 0, 0.6);
                    letter-spacing: 0.00938em;
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 0.95rem;
                }
            }

            span span {
                background-color: rgba(0, 0, 0, 0.07);
            }

            &:hover {
                .list-item-button {
                    background-color: rgba(0, 0, 0, 0.04);

                    .list-item-text {
                        span {
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            }

            &.active {
                .list-item-button {
                    background-color: rgba(0, 0, 0, 0.04);

                    .list-item-icon {
                        svg {
                            filter: invert(65%) sepia(73%) saturate(403%) hue-rotate(356deg) brightness(87%) contrast(88%);
                        }
                    }

                    .list-item-text {
                        span {
                            color: #d29a3e;
                        }
                    }
                }
            }
        }
    }

    .css-9mgopn-MuiDivider-root {
        border-color: rgba(224, 224, 224, 0.5);
        opacity: 1;
    }
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #fff !important;
}

nav.navbar {
    background-color: #fff;
    box-shadow: 1px 2px 3px rgb(0 0 0 / 1%);
    padding-top: 12px;
    padding-bottom: 12px;

    .container {
        padding-top: 0px;
        padding-bottom: 0px;

        &.container-fluid {
            .navbar-brand {
                display: flex;
                align-items: center;
                gap: 7px;
                padding: 0;

                .brand-text {
                    color: transparent;
                    font: 400 14px/20px Google Sans Text,Arial,Helvetica,sans-serif;
                    font-size: 10.5px;
                    letter-spacing: .3px;
                    font-weight: 500;
                    white-space: nowrap;
                    text-transform: uppercase;
                    line-height: 1.4;
                    background-image: linear-gradient(90deg, #d09636 0%, #d9ab5a 51%, #dbb97b 100%);
                    background-clip: text;

                    @media (max-width: 991.98px) {
                        font-size: 8.925px;
                    }
                }
            }

            .navbar-toggler {
                border: none;
                padding: 0.25rem 0;

                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .offcanvas {
            .offcanvas-body {
                .navbar-nav {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-grow: 1;
                    gap: 1rem;

                    .nav-item {
                        .nav-link {
                            line-height: 1.5;
                            letter-spacing: 0.00938em;
                            color: rgba(0, 0, 0, 0.55);
                            font-size: 0.75rem;
                            font-family: 'Source Sans Pro', sans-serif;
                            text-transform: uppercase;
                            font-weight: 500;
                            padding-left: 0;
                            padding-right: 0;

                            &:hover {
                                color: rgba(0, 0, 0, 0.55);
                            }

                            &.active {
                                color: #d29a3e;
                            }
                        }
                    }
                }
            }

            &.show {
                .offcanvas-body {
                    .nav-link {
                        color: rgba(0,0,0,.55);

                        &.active {
                            color: rgba(0,0,0,.9);
                        }
                    }
                }
            }

            .offcanvas-header {
                text-transform: uppercase;
            }
        }
    }
}
