.link-contained {
    a.MuiButton-root {
        background-color: #a87305;
        color: rgba(0, 0, 0, 0.87);
        color: rgba(255, 255, 255, 0.95);
        border-color: rgba(255, 255, 255, 0.95);
        border-radius: 4px;

        &:hover {
            text-decoration: none;
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        }
    }
}

.link-contained-more {
    a.MuiButton-root {
        color: rgba(255, 255, 255, 0.95);
        box-shadow: none;
        border-radius: 180px !important;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        background-image: linear-gradient(45deg, #D29A3E 0%, #DBAF62  51%, #DDBD83  100%);

        &:hover {
            background-image: linear-gradient(45deg, #c88e2f 0%, #d6a44d  51%, #d8b26f  100%);
            box-shadow: none;
        }
    }

    &.career {
        a.MuiButton-root {
            @media (max-width: 539.98px) {
                width: 100% !important;
            }
        }
    }
}

.link-outlined-contact {
    a.MuiButton-root {
        transition: none;
        font-size: 14px;
        padding: 2px 10px;
        border-radius: 3px;
        box-shadow: none;

        &.light {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.95);
            border: 1px solid rgba(255, 255, 255, 0.95);
        }

        &.dark {
            color: #505050;
            border: 1px solid #505050;
        }
    }
}

.link-outlined {
    a.MuiButton-root {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.95);
        border-color: rgba(255, 255, 255, 0.95);
        border-radius: 4px;
    }
}

.link-outlined-card {
    a.MuiButton-root {
        background-color: transparent;
        color: #d6a44d;
        border-color: none;
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        font-weight: 400;

        .css-155nyw6-MuiButton-endIcon {
            margin-left: 0;
        }
    }
}

.link-contained-submit {
    padding-top: 30px;

    button.MuiButton-root {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.95);
        box-shadow: none;
        border-radius: 180px !important;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        background-image: linear-gradient(45deg, #D29A3E 0%, #DBAF62  51%, #DDBD83  100%);

        &:hover {
            background-image: linear-gradient(45deg, #c88e2f 0%, #d6a44d  51%, #d8b26f  100%);
            box-shadow: none;
        }

        @media (max-width: 539.98px) {
            width: 100% !important;
        }
    }
}

.link-contained-close {
    button.MuiButton-root {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.6);
        box-shadow: none;
        border-radius: 180px !important;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 500;
        background-color: #f5f5f5;
        font-size: 0.8375rem;


        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            box-shadow: none;
        }

        @media (max-width: 539.98px) {
            width: 100% !important;
        }
    }
}

.show-more-cards {
    background-color: #fff!important;
    color: #d6a44d !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.04) !important;
    // box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.01) !important;
    border-radius: 8px !important;
    font-size: 11.5px !important;
    letter-spacing: 0.02857em !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out !important;


    // border: 1px solid #e7e7e7 !important;

    span {
        margin-left: 3px !important;
    }

    span span span {
        background-color: transparent !important;
    }

    @media (hover: hover) {
        &:hover {
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
            background-color: #f9f9f9 !important;
        }
    }
}

.show-all-cards {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 16px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.45) !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;

    @media (hover: hover) {
        &:hover {
            color: rgba(0, 0, 0, 0.55) !important;
        }
    }
}
