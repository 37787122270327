.view-wrapper {
    .view-body {
        .body-wrapper {
            &.treatment {
                display: grid;
                row-gap: 20px;
                grid-template-columns: 100%;

                .treatment-navigation {
                    .navigation-wrapper {
                        background-color: #fff;
                        padding: 0;
                        border-radius: 8px;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);

                        .MuiTabs-root {
                            a {
                                text-transform: none;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 400;
                                font-size: 1rem;
                                letter-spacing: 0.00938em;
                                border-radius: 8px;
                                padding: 16px;
                                color: rgba(0, 0, 0, 0.7);

                                &:hover {
                                    color: rgba(0, 0, 0, 0.7);
                                }

                                span span span {
                                    background-color: transparent !important;
                                }
                            }

                            .MuiButtonBase-root {
                                span span span {
                                    background-color: transparent !important;
                                }
                            }
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
                            color: #D29A3E;
                        }

                        .Mui-selected {
                            color: #D29A3E !important;
                        }
                    }
                }

                .MuiTabs-flexContainer:first-child {
                    padding-left: 0;
                }

                .treatment-description {
                    display: grid;
                    gap: 20px;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: start;

                    .treatment-tabels {
                        grid-column: span 2;

                        .table-container {
                            border-radius: 8px;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                        }
                    }

                    .treatment-details {
                        grid-column: span 2;

                        @media (max-width: 991.98px) {
                            grid-column: span 3;
                        }


                    }

                    .treatment-proposed {
                        grid-column: span 1;
                        text-decoration: none;

                        @media (max-width: 991.98px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
