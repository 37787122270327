html,
body,
#root,
.app {
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    overflow-x: hidden;
    background-color: #f5f5f5;
    touch-action: manipulation;
}

// body::-webkit-scrollbar {
    // display: none;
// }

main {
    flex: 1 1;
}

.view-wrapper {
    .view-body {
        .body-wrapper {
            padding-top: 20px;
        }
    }

    .view-header {
        .header-wrapper {
            display: grid;
            align-content: space-between;
            padding-top: 1.2rem;
            padding-bottom: 0rem;

            .nav-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                padding-bottom: 20px;
            }
        }
    }
}

.section-wrapper {
    &.footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        padding: 0 0;
        margin-bottom: 0;
        margin-top: 0;

        @media (max-width: 1199.98px) and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;

            grid-template-areas:
                "services technology"
                "business contact";

            .technology {
                grid-area: technology;
            }

            .services {
                grid-area: services;
            }

            .business {
                grid-area: business;
            }

            .contact {
                grid-area: contact;
            }
        }

        @media (max-width: 539.98px) {
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }
}

.card-wrapper {
    &.services {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        .alert-empty {
            grid-column: 1 / -1;    /* Rozciągnij na wszystkie kolumny */
            width: 100%;            /* Zajmij całą szerokość wiersza */
        }

        @media (max-width: 1199.98px) and (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 991.98px) and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 767.98px) and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 539.98px) {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    &.career {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        .alert-empty {
            grid-column: 1 / -1;    /* Rozciągnij na wszystkie kolumny */
            width: 100%;            /* Zajmij całą szerokość wiersza */
        }

        @media (max-width: 1199.98px) and (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 991.98px) and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 767.98px) and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 539.98px) {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    &.treatment {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 1199.98px) and (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 991.98px) and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 767.98px) and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 539.98px) {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    &.special-offer {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media (max-width: 1199.98px) and (min-width: 992px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 991.98px) and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 767.98px) and (min-width: 540px) {
            grid-template-columns: 1fr 1fr;
            gap: 15px;
        }

        @media (max-width: 539.98px) {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }

    &.team {
        &.section {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr;
                gap: 15px;
            }
        }

        &.view {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr;
                gap: 15px;
            }
        }

        &.page {
            display: grid;
            gap: 15px;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 15px;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &.technology {
        &.section {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.view {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr;
                gap: 15px;
            }
        }

        &.page {
            display: grid;
            gap: 15px;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 15px;

            @media (max-width: 1199.98px) and (min-width: 992px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 991.98px) and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 767.98px) and (min-width: 540px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 539.98px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &.services-proposed {
        display: grid;
        gap: 15px;
    }

    &.team-proposed {
        display: grid;
        gap: 15px;
    }

    &.technology-proposed {
        display: grid;
        gap: 15px;
    }
}
