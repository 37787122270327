.image {
    .lazy-load-image-wrapper {
        background-color: #e6e6e6;

        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 60%; /* 66.66% Utrzymuje proporcje 3:2 */
        border-radius: 8px;

        &.square {
            padding-bottom: 100%; /* 100% Utrzymuje proporcje 1:1 */
        }

        span {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        span img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            transition: 0.5s all ease-out !important;
        }
    }

    .description {
        padding-top: .75rem;

        &.hide {
            display: none;
        }

        .source {
            color: #606060;
            font-size: .85rem;
            font-family: "Roboto", sans-serif;
            font-weight: 300;

            .dot-divider {
                padding: 0 4px;
            }

            .author {
                font-weight: 300;
                color: #606060;
                font-size: .85rem;

                &.link {
                    text-decoration: none;

                    &:hover {
                        color: #535353;
                    }
                }
            }
        }
    }
}
