.card-team-proposed {
    .card {
        display: grid;
        grid-template-columns: 120px 1fr;
        height: 100%;
        width: 100%;
        border: none;
        background-color: #fff;
        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
        border-radius: 8px;

        @media (hover: hover) {
            &:hover {
                box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
                transition: all 0.3s ease-in-out;
            }
        }

        .card-media {
            background-color: #e6e6e6;
            height: 120px;

            .card-image {
                object-fit: cover;
                height: 120px;
                width: 120px;
                aspect-ratio: auto 120 / 120;
                border-top-left-radius: 8px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 0;
            }
        }

        .card-content {
            height: 120px;
            padding: 10px;
            display: grid;
            row-gap: 5px;

            .card-title {
                margin: 0;
                letter-spacing: 0.00938em;
                // line-height: 1.15;
                line-height: 1.2;
                font-family: 'Roboto', sans-serif;
                font-size: 1rem;
                // color: rgba(0, 0, 0, 0.7);
                color: rgba(0, 0, 0, 0.67);
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-description {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                letter-spacing: 0.00938em;
                text-align: left;
                font-size: 12.5px;
                font-weight: 300;
                color: #606060;

                .card-speciality {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .card-experience {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-link {
                padding: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1;
                justify-content: flex-start;
                background-color: transparent;
                color: #d6a44d;
                border-color: none;
                font-size: 10.5px;
                font-weight: 400;
                width: fit-content;
                z-index: 1;

                span span {
                    background-color: transparent !important;
                }
            }

            .stretched-link {
                display: block;
                height: 100%;
                z-index: 0;
            }
        }
    }
}
