.card-team {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: block;
    height: 320px;

    .card {
        height: 100%;
        width: 100%;
        border: none;
        box-shadow: none;
        margin-bottom: 1.5rem;
        margin-bottom: 0rem;
        background-color: #fff;
        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
        border-radius: 8px;

        @media (hover: hover) {
            &:hover {
                box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
                transition: all 0.3s ease-in-out;
            }
        }

        span {
            background-color: #e3e3e3;
            height: 130px;

            .card-background {
                height: 130px;
                object-fit: cover;
                object-position: center;
                filter: brightness(.94);
            }
        }

        .card-background-color {
            height: 130px;
            background-color: #e3e3e3;
        }

        .card-image-background {
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
            width: 170px;
            height: 170px;
            border-radius: 50%;
            border: 4px solid #fff;
            margin-top: -85px;
            background-color: #ebebeb;

            .lazy-load-image-background {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                right: auto;
                display: block;
                margin-left: auto;
                margin-right: auto;
                z-index: 2;
                width: 170px;
                height: 170px;
                border-radius: 50%;
                height: 100%;
                width: 100%;

                .card-image {
                    z-index: 2;
                    width: 170px;
                    height: 170px;
                    border-radius: 50%;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .card-content {
            padding: 16px 12px 0px 12px;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            margin-bottom: -10px;

            .card-title {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1;
                letter-spacing: 0.00938em;
                text-align: center;
                font-size: 12.8px;
                font-weight: 300;
                color: #000;
            }

            .card-name {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                text-align: center;
                margin-bottom: 0.35em;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-speciality {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                text-align: center;
                font-size: 14.5px;
                font-weight: 300;
                color: #606060;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .card-actions {
            justify-content: center;
            align-items: center;
            padding-top: 0;
            padding-bottom: 22px;
            gap: 10px
        }
    }
}
