.view-wrapper {
    .view-body {
        .body-wrapper {
            &.specialists-details {
                display: grid;
                gap: 3rem;
                grid-template-columns: repeat(3, 1fr);

                @media (max-width: 991.98px) {
                    grid-template-columns: 1fr;
                }

                .career-description {
                    grid-column: span 2;

                    @media (max-width: 991.98px) {
                        grid-column: span 3;
                    }

                    .box-career {
                        margin-bottom: 32px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .box-details {
                            .details {
                                display: grid;
                                grid-template-columns: 55% 1fr;
                                justify-items: stretch;

                                @media (max-width: 991.98px) {
                                    grid-template-columns: 1fr;
                                }
                            }

                            .list-item-text {
                                span {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 300;
                                    font-size: 1rem;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    display: block;
                                    color: #535353;
                                }

                                p {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 300;
                                    font-size: 0.875rem;
                                    line-height: 1.43;
                                    letter-spacing: 0.01071em;
                                    display: block;
                                    color: rgba(0, 0, 0, 0.6);
                                }
                            }
                        }

                        .box-benefits {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            gap: 15px;

                            @media (max-width: 1199.98px) and (min-width: 992px) {
                                grid-template-columns: 1fr 1fr 1fr;
                            }

                            @media (max-width: 991.98px) and (min-width: 768px) {
                                grid-template-columns: 1fr 1fr 1fr;
                            }

                            @media (max-width: 767.98px) and (min-width: 540px) {
                                grid-template-columns: 1fr 1fr 1fr;
                            }

                            @media (max-width: 539.98px) {
                                grid-template-columns: 1fr 1fr;
                            }

                            .benefit-item {
                                position: relative;
                                overflow: hidden;
                                width: 100%; /* Szerokość 100% */
                                padding-bottom: 100%; /* Ustawienie wysokości jako 100% szerokości */
                                background-color: #ebebeb;
                                // background-color: #fff;
                                border-radius: 8px;
                                // box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.04);
                                box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.01);
                                // border: 1px solid #e7e7e7;

                                .benefit-content {
                                    position: absolute;
                                    align-items: center;
                                    display: grid;
                                    grid-template-rows: 1fr 1fr;
                                    gap: 20px;
                                    height: 100%;
                                    justify-content: center;
                                    text-align: center;
                                    width: 100%;

                                    .benefit-icon {
                                        display: flex;
                                        align-items: flex-end;
                                        justify-content: center;
                                        height: 100%;

                                        svg {
                                            color: #D29A3E;
                                            width: 32px;
                                            height: 32px;
                                        }
                                    }

                                    .benefit-description {
                                        height: 100%;
                                        padding: 0 15px;
                                        color: #535353;
                                        font-size: 15.5px;
                                        font-family: "Roboto", sans-serif;
                                        letter-spacing: 0.00538em;
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                    }

                    .box-image {
                        margin-bottom: 24px;
                        margin-top: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .header {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        color: #d29a3e;
                        font-size: 1.25rem;
                        line-height: 1.5;
                        letter-spacing: 0.00938em;
                        text-align: left;
                        margin-bottom: 16px;

                        &.alternative {
                            color: #535353;
                        }
                    }

                    .paragraph {
                        font-size: 15.5px;
                        font-family: 'Roboto', sans-serif;
                        color: #535353;
                        font-weight: 300;
                        margin-bottom: 10px;
                        letter-spacing: 0.00538em;
                        line-height: 1.6;

                        .link {
                            text-decoration: none;
                            color: #d6a44d;
                            font-weight: 300;
                        }

                        &.heading {
                            margin-top: 16px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .list-item-avatar {
                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }

                    .list-item-text {
                        span {
                            font-family: 'Roboto', sans-serif;
                            font-weight: 300;
                            font-size: 15.5px;
                            letter-spacing: 0.00538em;
                            color: #535353;
                        }
                    }

                    .table-header {
                        font-size: 13.5px;
                        font-family: 'Roboto', sans-serif;
                        color: rgba(0, 0, 0, 0.75);
                        font-weight: 400;
                        margin-bottom: 5px;
                        letter-spacing: 0.00538em;
                        line-height: 1.6;
                    }

                    .table-container {
                        border-radius: 8px;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                        box-shadow: none;
                        margin-bottom: 15px;
                    }

                    .accordian {
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                    }

                }

                .proposed-wrapper {
                    grid-column: span 1;

                    align-content: start;
                    display: grid;
                    gap: 3rem;

                    @media (max-width: 991.98px) {
                        grid-column: span 3;
                    }

                    .box-proposed {
                        .header-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .header {
                                font-family: 'Roboto', sans-serif;
                                font-weight: 400;
                                color: #535353;
                                font-size: 1.25rem;
                                line-height: 1.5;
                                letter-spacing: 0.00938em;
                                text-align: left;
                                margin-bottom: 16px;
                            }
                        }

                        .card-wrapper {
                            display: grid;
                            gap: 15px;

                            @media (max-width: 991.98px) and (min-width: 540px) {
                                grid-template-columns: 1fr 1fr;
                                gap: 15px;
                            }

                            @media (max-width: 539.98px) {
                                grid-template-columns: 1fr;
                                gap: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
