.badge {
    // padding: 0.22rem 0.8375rem;
    // border-radius: 20px;
    // text-transform: uppercase;
    // text-align: center;
    // font-size: 11px;
    // cursor: default;
    // font-family: 'Roboto', sans-serif;
    // font-weight: 500;
    // display: inline;




    height: 24px;
    min-width: 22px;
    line-height: 0;
    border-radius: 20px;
    // border-radius: 6px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    text-transform: capitalize;
    padding: 0px 8px;
    // color: #B71D18;
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    // background-color: rgba(255, 86, 48, 0.16);
    font-weight: 500;



    &.success {
        color: #12633e;
        color: #157449;
        background-color: #d1e7dd;
    }

    &.warning {
        color: #997404;
        background-color: #f3e8d3;
    }

    &.danger {
        color: #B71D18;
        // color: #ce211b;
        background-color: rgba(255, 86, 48, 0.16);
    }

    &.margin {
        margin-left: 8px;
    }
}
