.toast-container {
    padding: 1.5rem;
    z-index: 999;

    .toast {
        &.success {
            // color: #12633e;
            // background-color: #d1e7dd;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            border-radius: 8px;
            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
            width: 400px;
            padding: 8px;

            color: #fff;
            background: linear-gradient(45deg, #4CAF50 0%, #66BB6A 51%, #81C784 100%);

            .toast-body {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    font-size: 19px;
                    // color: #12633e;
                    color: #fff;
                }

                .toast-text {
                    line-height: 17px;
                }
            }
        }

        &.danger {
            // color: #dc3545;
            // background-color: rgb(253, 237, 237);
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            border-radius: 8px;
            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
            width: 400px;
            padding: 8px;

            color: #fff;
            background: linear-gradient(45deg, #d63f3f 0%, #d65a5a 51%, #d67777 100%);

            .toast-body {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    font-size: 19px;
                    // color: #dc3545;
                    color: #fff;
                }

                .toast-text {
                    line-height: 17px;
                }
            }
        }

        // button {
        //     filter: invert(34%) sepia(21%) saturate(1178%) hue-rotate(100deg) brightness(99%) contrast(105%);
        //     width: 6px;
        //     height: 6px;
        //     opacity: .8;
        // }

        @media (max-width: 539.98px) {
            width: 100%;
        }
    }

    @media (max-width: 539.98px) {
        padding: .6rem;
        padding: 8px 15px;
        width: 100%;
    }
}
