.card-services {
    text-decoration: none;

    .card {
        height: 100%;
        width: 100%;
        border: none;
        background-color: #fff;
        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
        border-radius: 8px;

        @media (hover: hover) {
            &:hover {
                box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
                transition: all 0.3s ease-in-out;

                // .card-media span img {
                //     transform: scale(1.045);
                // }
            }
        }

        .card-media {
            background-color: #e6e6e6;

            position: relative;
            overflow: hidden;
            width: 100%;
            padding-bottom: 60%; /* 66.66% Utrzymuje proporcje 3:2 */
            // border-radius: 8px;

            span {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            span img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                transition: 0.5s all ease-out !important;
            }

            // @media (min-width: 1199.98px) {
            //     height: 258px;
            // }
        }

        .card-content {
            // padding: 15px 20px;
            padding: 16px;

            .card-title {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.7);
                font-size: 1.25rem;
                // line-height: 1.5;
                line-height: 1.1;
                letter-spacing: 0.00938em;
                text-align: left;
                // margin-bottom: 0.35em;
                margin-bottom: 0.5rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-description {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1.5;
                text-align: left;
                font-size: 0.875rem;
                letter-spacing: 0.01071em;
                font-weight: 300;
                color: #606060;
                // margin-bottom: 8px;
                margin-bottom: 0.6rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-avatar-group {
                margin-top: 0;
                justify-content: flex-end;

                .card-avatar {
                    img {
                        background-color: darken(#f5f5f5, 4%);
                    }
                }
            }
        }

        .card-actions {
            display: flex;
            justify-content: flex-start;
            gap: 15px;
            // padding: 0 20px 15px 20px;
            padding: 0 16px 16px 16px;

            .card-link {
                padding: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1;
                justify-content: flex-start;
                background-color: transparent;
                color: #d6a44d;
                border-color: none;
                font-size: 11.5px;
                font-weight: 400;
                width: fit-content;
                z-index: 1;

                span span {
                    background-color: transparent !important;
                }
            }
        }

        .stretched-link {
            display: block;
            height: 100%;
            z-index: 0;
        }
    }
}
