.scrollup {
    button.MuiButton-root {
        z-index: 1000;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 1.5rem;
        bottom: 1.5rem;
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        padding: 0;
        background-image: linear-gradient(45deg, #D29A3E 0%, #DBAF62  51%, #DDBD83  100%);
        border: none;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: none;

        &:hover {
            background-image: linear-gradient(45deg, #c88e2f 0%, #d6a44d  51%, #d8b26f  100%);

        }
    }
}

body.modal-open {
    .scrollup {
        button.MuiButton-root {
            display: none;
        }
    }
}
