footer {
    background-color: #202124;

    .section-wrapper {
        &.footer {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            padding-left: 16px;
            padding-right: 16px;

            .footer-heading {
                color: hsla(0,0%,100%,.5);
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                text-align: left;
                margin-bottom: 0.65em;
            }

            .footer-text {
                color: hsla(0,0%,100%,.3);
                font-family: 'Roboto', sans-serif;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                text-align: left;
                font-size: 14.5px;
                font-weight: 300;
                margin-bottom: 0.35em;
                text-decoration: none;
                width: fit-content;

                &.link:hover {
                    color: hsla(0,0%,100%,.5);
                }
            }

            .business-wrapper {
                display: grid;
            }

            .services-wrapper {
                display: grid;
            }

            .treatment-wrapper {
                display: grid;
            }

            .contact-wrapper {
                display: grid;

                .media-wrapper {
                    display: flex;
                    gap: 16px;

                    .link {
                        svg {
                            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
                            opacity: 0.3;

                            &:hover {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        &.info {
            text-align: center;
            margin: 0;
            padding: .7rem 0;
            background-color: #fff;
            font-family: 'Roboto', sans-serif;

            .copyright {
                color: rgba(0,0,0,.55);
                font-size: .75rem;

                @media (max-width: 539.98px) {
                    font-size: .6rem;
                }

                .design {
                    text-decoration: none;
                    color: rgba(0,0,0,.55);
                }
            }
        }
    }
}
