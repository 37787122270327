.view-wrapper {
    .view-body {
        .body-wrapper {
            &.specialists-list {
                display: grid;
                row-gap: 20px;
                grid-template-columns: 100%;

                .specialists-navigation {
                    .navigation-wrapper {
                        background-color: #fff;
                        padding: 0;
                        border-radius: 8px;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);

                        .MuiTabs-root {
                            a {
                                text-transform: none;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 400;
                                font-size: 1rem;
                                letter-spacing: 0.00938em;
                                border-radius: 8px;
                                padding: 16px;
                                color: rgba(0, 0, 0, 0.7);

                                &:hover {
                                    color: rgba(0, 0, 0, 0.7);
                                }

                                span span span {
                                    background-color: transparent !important;
                                }
                            }

                            .MuiButtonBase-root {
                                span span span {
                                    background-color: transparent !important;
                                }
                            }
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
                            color: #D29A3E;
                        }

                        .Mui-selected {
                            color: #D29A3E !important;
                        }
                    }
                }

                .MuiTabs-flexContainer:first-child {
                    padding-left: 0;
                  }

                .team-description {
                    display: grid;
                    gap: 20px;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: start;

                    .team-tabels {
                        grid-column: span 2;

                        .table-container {
                            border-radius: 8px;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                        }
                    }

                    .team-details {
                        grid-column: span 2;

                        @media (max-width: 991.98px) {
                            grid-column: span 3;
                        }

                        .card-introduction {
                            height: 100%;
                            width: 100%;
                            border: none;
                            box-shadow: none;
                            margin-bottom: 15px;
                            background-color: #fff;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                            border-radius: 8px;

                            .lazy-load-image-background {
                                display: contents !important;
                            }

                            span {
                                background-color: #e3e3e3;
                                height: 130px;

                                .card-background {
                                    height: 130px;
                                    object-fit: cover;
                                    object-position: center;
                                    filter: brightness(.95);
                                }
                            }

                            .card-background-color {
                                height: 130px;
                                background-color: #e3e3e3;
                            }

                            .card-image {
                                position: relative;
                                display: block;
                                // margin-left: 63.5px;
                                margin-left: 16px;
                                margin-right: auto;
                                z-index: 2;
                                width: 170px;
                                height: 170px;
                                border-radius: 50%;
                                border: 4px solid #fff;
                                margin-top: -85px;
                                background-color: darken(#f5f5f5, 4%);
                                // height: 100%;
                                // width: 100%;
                            }

                            .card-content {
                                padding: 12px 20px 20px 20px;
                                display: flex;
                                flex-direction: column;
                                flex: 1 1 auto;


                                .card-name {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 400;
                                    font-size: 1.1rem;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    margin-bottom: 0.35em;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .card-speciality {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 1;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    font-size: 15px;
                                    font-weight: 300;
                                    margin-bottom: 20px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }

                                .card-text {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    font-size: 13.5px;
                                    font-weight: 300;
                                    margin-bottom: 15px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        .card-education {
                            height: 100%;
                            width: 100%;
                            border: none;
                            box-shadow: none;
                            margin-bottom: 15px;
                            background-color: #fff;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                            border-radius: 8px;

                            .card-content {
                                padding: 12px 20px 20px 20px;
                                display: flex;
                                flex-direction: column;
                                flex: 1 1 auto;

                                .card-header {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 400;
                                    font-size: 1.1rem;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    margin-bottom: 0.35em;
                                }

                                .card-text {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    font-size: 13.5px;
                                    // font-size: 15.5px;
                                    font-weight: 300;
                                    // color: #606060;
                                }
                            }
                        }

                        .card-experience {
                            height: 100%;
                            width: 100%;
                            border: none;
                            box-shadow: none;
                            margin-bottom: 15px;
                            background-color: #fff;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                            border-radius: 8px;

                            .card-content {
                                padding: 12px 20px 20px 20px;
                                display: flex;
                                flex-direction: column;
                                flex: 1 1 auto;

                                .card-header {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 400;
                                    font-size: 1.1rem;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    margin-bottom: 0.35em;
                                }

                                .card-text {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    font-size: 13.5px;
                                    // font-size: 15.5px;
                                    font-weight: 300;
                                    // color: #606060;
                                }
                            }
                        }

                        .card-interested {
                            height: 100%;
                            width: 100%;
                            border: none;
                            box-shadow: none;
                            margin-bottom: 15px;
                            background-color: #fff;
                            box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                            border-radius: 8px;

                            .card-content {
                                padding: 12px 20px 20px 20px;
                                display: flex;
                                flex-direction: column;
                                flex: 1 1 auto;

                                .card-header {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 400;
                                    font-size: 1.1rem;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    margin-bottom: 0.35em;
                                }

                                .card-text {
                                    margin: 0;
                                    font-family: 'Roboto', sans-serif;
                                    line-height: 1.5;
                                    letter-spacing: 0.00938em;
                                    text-align: left;
                                    font-size: 13.5px;
                                    font-weight: 300;
                                }
                            }
                        }
                    }

                    .team-proposed {
                        grid-column: span 1;
                        text-decoration: none;

                        @media (max-width: 991.98px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
