.card-special-offer {
    text-decoration: none;
    cursor: pointer;

    .card {
        height: 100%;
        width: 100%;
        border: none;
        background-color: #fff;
        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
        border-radius: 8px;

        @media (hover: hover) {
            &:hover {
                box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
                transition: all 0.3s ease-in-out;
            }
        }

        .card-header {
            border-bottom: none;
            background-color: #fff;
            padding: 16px;

            .MuiCardHeader-title {
                margin: 0;
                letter-spacing: 0.00938em;
                line-height: 1.1;
                font-family: "Roboto", sans-serif;
                font-size: 1.1rem;
                color: rgba(0, 0, 0, 0.7);
                margin-bottom: 0.5rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .MuiCardHeader-subheader {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 0.755rem;
                line-height: 1;
                letter-spacing: 0.01071em;
                color: rgba(0, 0, 0, 0.45);
                display: block;
            }
        }

        .card-media {
            background-color: #e6e6e6;

            @media (min-width: 1199.98px) {
                height: 286px;
            }
        }

        .card-content {
            padding: 16px;

            .card-description {
                margin: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1.5;
                text-align: left;
                font-size: 0.875rem;
                letter-spacing: 0.01071em;
                font-weight: 300;
                color: #606060;
                margin-bottom: 0.3rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-link {
                padding: 0;
                font-family: 'Roboto', sans-serif;
                line-height: 1;
                justify-content: flex-start;
                background-color: transparent;
                color: #d6a44d;
                border-color: none;
                font-size: 11.5px;
                font-weight: 400;
                width: fit-content;

                span span {
                    background-color: transparent !important;
                }
            }
        }
    }
}
