.MuiBreadcrumbs-root {
    display: flex;
    justify-content: flex-start;
    // padding-bottom: 1rem;

    .MuiBreadcrumbs-li {
        a {
            text-decoration: none !important;
            font-family: 'Source Sans Pro', sans-serif !important;
            font-size: 0.8rem !important;
            font-weight: 300 !important;
            color: rgba(0, 0, 0, 0.4) !important;
            display: flex;
            align-items: center;

            &:hover {
                color: rgba(0, 0, 0, 0.5) !important;
            }
        }
    }

    .MuiTypography-root {
        color: rgba(0, 0, 0, 0.4) !important;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 0.8rem !important;
        font-weight: 300 !important;
    }
}

.MuiBreadcrumbs-separator {
    margin-left: 3px !important;
    margin-right: 3px !important;
    color: rgba(0, 0, 0, 0.4) !important;
}

.breadcrumb {
    &.back {
        ol li a {
            // color: rgba(0, 0, 0, 0.55) !important;
            // color: #D29A3E !important;
            // border-radius: 8px;
            font-weight: 400 !important;
            // font-family: 'Roboto', sans-serif !important;
            // margin-left: -1px;

            // &:hover {
                // color: #D29A3E !important;
            // }

            svg {
                font-size: 14px;
                margin-right: 3px;
            }
        }
    }

    &.nav {
        @media (max-width: 991.98px) {
            display: none;
        }
    }
}
