.view-wrapper {
    .view-body {
        .body-wrapper {
            &.contact {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 40px;

                @media (max-width: 991.98px) {
                    grid-template-columns: 100%;
                }

                .contact-details-wrapper {
                    display: flex;
                    align-items: flex-start;
                    padding-top: 24px;

                    @media (max-width: 991.98px) {
                        padding-top: 0;
                    }

                    .list-item-text {
                        span {
                            margin: 0;
                            font-family: 'Roboto', sans-serif;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 1.5;
                            letter-spacing: 0.00938em;
                            display: block;
                            color: #535353;
                            font-weight: 400;
                        }

                        p {
                            margin: 0;
                            font-family: 'Roboto', sans-serif;
                            font-weight: 400;
                            font-size: 0.875rem;
                            line-height: 1.43;
                            letter-spacing: 0.01071em;
                            display: block;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .contact-form-wrapper {
                    .card-form {
                        background-color: #fff;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                        border-radius: 8px;
                        padding: 20px;
                    }
                }

                .header {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 1.25rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    text-align: left;
                    margin-bottom: 0.35em;
                }

                .paragraph {
                    font-size: 15.5px;
                    font-family: 'Roboto', sans-serif;
                    color: #535353;
                    font-weight: 300;
                    margin-bottom: 10px;
                    letter-spacing: 0.00538em;
                    line-height: 1.6;
                }

                .link {
                    text-decoration: none;
                    color: inherit;
                }

                .list-item-avatar {
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }

                .list-item-text {
                    span {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 300;
                        font-size: 15.5px;
                        letter-spacing: 0.00538em;
                    }
                }
            }
        }
    }
}
