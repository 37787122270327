.pswp__dynamic-caption--mobile {
    background: none !important;
    line-height: 30px !important;
    padding: 15px 20px !important;
    text-shadow: 1px 1px 3px #4f4f4f !important;
    opacity: 0.85 !important;

    .description {
        display: block !important;
        margin-bottom: 10px;
    }

    .info {
        display: block !important;
        font-size: 12.5px;
        line-height: 1.5 !important;
        margin-bottom: 10px !important;
    }
}

.pswp__dynamic-caption {
    .description {
        display: block !important;
        margin-bottom: 10px;
    }

    .info {
        display: block !important;
    }
}
