.sample-next-arrow {
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background: #fff;
    right: -45px;
    box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
    cursor: pointer;
    height: 38px;
    width: 38px;
    border-radius: 999rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0,0,0,.5);

    svg {
        filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(327deg) brightness(114%) contrast(114%);
        opacity: 0.6;
        user-select: none;

        &:hover {
            opacity: 0.9;
            cursor: pointer;
        }
    }
}

.sample-prev-arrow {
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translate(0, -50%);
    border: none;
    outline: none;
    background: #fff;
    left: -45px;
    box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
    cursor: pointer;
    height: 38px;
    width: 38px;
    border-radius: 999rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    color: rgba(0,0,0,.5);

    svg {
        filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(327deg) brightness(114%) contrast(114%);
        opacity: 0.6;
        user-select: none;

        &:hover {
            opacity: 0.9;
            cursor: pointer;
        }
    }
}
