.price-section {
    .jarallax {
        @media (max-width: 991.98px) {
            display: none;
        }
    }

    @mixin bd-bg-overlay($color, $opacity) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 0;
        background: none repeat $color;
        opacity: $opacity;
    }

    @mixin bd-relative-zindex {
        position: relative;
        z-index: 999;
    }

    .jumbotron {
        display: none;

        @media (max-width: 991.98px) {
            display: block;
        }

        .photo-wrapper {
            position: relative;
            background: url('../../assets/prices/prices2.jpg') 52% 50% no-repeat;
            background-size: cover;

            &::after {
                @include bd-bg-overlay(rgb(0, 0, 0), .2);
            }

            .content-wrapper {
                @include bd-relative-zindex;
                text-align: center;

                .description-wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0;
                    text-align: center;
                    background: 0 0;
                    border: 0;
                    flex-direction: column;

                    .jumbotron-header {
                        text-align: center;
                        margin-bottom: 0.35em;
                        color: #fff;
                        font-family: 'Lato', sans-serif;
                        font-size: 35px;
                        line-height: 1.208em;
                        letter-spacing: .045em;
                        text-transform: uppercase;
                        font-weight: 400;
                        text-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, .15);

                        @media (max-width: 539.98px) {
                            font-size: 24px;
                        }
                    }

                    .jumbotron-subheader {
                        line-height: 1.6;
                        letter-spacing: 0.0075em;
                        text-align: center;
                        color: #fff;
                        font-size: 21px;
                        font-weight: 400;
                        font-family: Raleway, sans-serif;
                        text-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, .15);

                        @media (max-width: 539.98px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
