.view-wrapper {
    .view-body {
        .body-wrapper {
            &.prices {
                display: grid;
                gap: 20px;
                grid-template-columns: repeat(3, 1fr);

                @media (max-width: 991.98px) {
                    column-gap: 0;
                    row-gap: 20px;
                    grid-template-columns: 100%;
                }

                .prices-navigation {
                    grid-column: span 1;

                    .navigation-wrapper {
                        background-color: #fff;
                        padding: 0;
                        border-radius: 8px;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                        position: sticky;
                        top: 2rem;

                        .list {
                            padding-top: 0;
                            padding-bottom: 0;

                            .list-item {
                                padding: 0;

                                &:first-child {
                                    .list-item-button {
                                        border-top-left-radius: 8px;
                                        border-top-right-radius: 8px;
                                    }
                                }

                                &:last-child {
                                    .list-item-button {
                                        border-bottom-left-radius: 8px;
                                        border-bottom-right-radius: 8px;
                                    }
                                }

                                .list-item-button {
                                    border-radius: 0;
                                    color: #535353;
                                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;

                                    &:hover {
                                        background-color: #f9f9f9;
                                        color: #535353;
                                    }

                                    &.Mui-selected {
                                        background-color: #f9f9f9;
                                        color: #d29a3e;

                                        .list-item-icon {
                                            color: #d29a3e !important;
                                        }
                                    }

                                    .list-item-text span {
                                        font-family: 'Roboto', sans-serif;
                                        font-weight: 400;
                                        font-size: 1rem;
                                        letter-spacing: 0.00938em;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;

                                    }

                                    .list-item-icon {
                                        min-width: auto;
                                        color: rgba(0, 0, 0, 0.45);
                                    }

                                    span span {
                                        background-color: transparent;
                                    }
                                }
                            }

                            .divider {
                                border-color: rgba(224, 224, 224, 0.6);
                                opacity: 1;
                            }
                        }

                        .MuiTabs-root {
                            a {
                                text-transform: none;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 400;
                                font-size: 1rem;
                                letter-spacing: 0.00938em;
                                border-radius: 8px;
                                padding: 16px;
                                color: rgba(0, 0, 0, 0.7);

                                &:hover {
                                    color: rgba(0, 0, 0, 0.7);
                                }

                                span span span {
                                    background-color: transparent !important;
                                }
                            }

                            .MuiButtonBase-root {
                                span span span {
                                    background-color: transparent !important;
                                }
                            }
                        }

                        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
                            color: #D29A3E;
                        }

                        .Mui-selected {
                            color: #D29A3E !important;
                        }
                    }
                }

                .prices-tabels {
                    grid-column: span 2;

                    .table-container {
                        border-radius: 8px;
                        box-shadow: 1px 2px 6px rgb(0 0 0 / 4%);
                    }
                }

                .nowrap {
                    white-space: nowrap;
                }
            }
        }
    }
}

.line-break {
    white-space: pre-line;
}
