.heading-view {
    margin: 0 !important;
    text-align: left !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 26px !important;
    line-height: 1.208em !important;
    letter-spacing: .045em !important;

    sub {
        font-size: 19px;
        line-height: 1.208em;
    }

    @media (max-width: 991.98px) {
        font-size: 22px !important;
    }
}
