.view-wrapper {
    .view-body {
        .body-wrapper {
            &.clinic {
                .clinic-description-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;

                    @media (max-width: 991.98px) {
                        grid-template-columns: 1fr;
                        gap: 0;
                    }
                }

                .clinic-services-wrapper {
                    .services-item-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 20px;

                        @media (max-width: 539.98px) {
                            grid-template-columns: 1fr;
                            gap: 0;
                        }
                    }
                }

                .clinic-technology-wrapper {
                    .technology-item-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 20px;
                    }
                }

                .photos-wrapper {
                    margin-top: 24px;
                    margin-bottom: 34px;

                    .pswp-gallery {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-template-rows: repeat(3, 1fr);
                        grid-column-gap: 5px;
                        grid-row-gap: 5px;
                        padding: 0;

                        a {
                            list-style: none;
                            background-color: #e6e6e6;

                            .box {
                                position: relative;
                                overflow: hidden;
                                width: 100%;
                                height: 210px;

                                span {
                                    position: absolute;

                                    img {
                                        object-fit: cover;
                                        object-position: center;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        transition: 0.5s all ease-out !important;
                                    }
                                }
                            }

                            &:hover {
                                .box {
                                    span {
                                        img {
                                            filter: brightness(.9);
                                            transition: 0.5s all ease-out;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            &:nth-child(1) {
                                grid-column: 1 / 3;
                                grid-row: 1 / 3;
                                border-top-left-radius: 8px;

                                .box {
                                    height: 425px;
                                    border-top-left-radius: 8px;

                                    span img {
                                        border-top-left-radius: 8px;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                grid-column: 3 / 3;
                                grid-row: 1 / 2;
                            }

                            &:nth-child(3) {
                                grid-column: 4 / 5;
                                grid-row: 1 / 2;
                                border-top-right-radius: 8px;

                                .box {
                                    border-top-right-radius: 8px;

                                    span img {
                                        border-top-right-radius: 8px;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                grid-column: 3 / 5;
                                grid-row: 2 / 4;
                                border-bottom-right-radius: 8px;

                                .box {
                                    height: 425px;
                                    border-bottom-right-radius: 8px;

                                    span img {
                                        border-bottom-right-radius: 8px;
                                    }
                                }
                            }

                            &:nth-child(5) {
                                grid-column: 1 / 2;
                                grid-row: 3 / 3;
                                border-bottom-left-radius: 8px;

                                .box {
                                    border-bottom-left-radius: 8px;

                                    span img {
                                        border-bottom-left-radius: 8px;
                                    }
                                }
                            }

                            &:nth-child(6) {
                                grid-column: 2 / 3;
                                grid-row: 3 / 4;
                            }

                            @media (max-width: 1199.98px) and (min-width: 992px) {
                                .box {
                                    height: 187.5px;
                                }

                                &:nth-child(1) {
                                    .box {
                                        height: 380px;
                                    }
                                }

                                &:nth-child(4) {
                                    .box {
                                        height: 380px;
                                    }
                                }
                            }

                            @media (max-width: 991.98px) and (min-width: 768px) {
                                .box {
                                    height: 147.5px;
                                }

                                &:nth-child(1) {
                                    .box {
                                        height: 300px;
                                    }
                                }

                                &:nth-child(4) {
                                    .box {
                                        height: 300px;
                                    }
                                }
                            }

                            @media (max-width: 767.98px) and (min-width: 540px) {
                                .box {
                                    height: 112.5px;
                                }

                                &:nth-child(1) {
                                    .box {
                                        height: 230px;
                                    }
                                }

                                &:nth-child(4) {
                                    .box {
                                        height: 230px;
                                    }
                                }
                            }

                            @media (max-width: 539.98px) {
                                .box {
                                    height: 170px;
                                }

                                &:nth-child(1) {
                                    grid-column: 1 / 3;
                                    grid-row: 1 / 2;
                                    border-top-right-radius: 8px;

                                    .box {
                                        height: 170px;
                                        border-top-right-radius: 8px;

                                        span img {
                                            border-top-right-radius: 8px;
                                        }
                                    }
                                }

                                &:nth-child(2) {
                                    grid-column: 1 / 2;
                                    grid-row: 2 / 3;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;

                                    .box {
                                        height: 170px;
                                        border-top-right-radius: 0;
                                        border-bottom-right-radius: 0;

                                        span img {
                                            border-top-right-radius: 0;
                                            border-bottom-right-radius: 0;
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    display: none;

                                    .box {
                                        height: 0;
                                    }
                                }

                                &:nth-child(4) {
                                    grid-column: 2 / 3;
                                    grid-row: 2 / 3;
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;

                                    .box {
                                        height: 170px;
                                        border-top-right-radius: 0;
                                        border-bottom-right-radius: 0;

                                        span img {
                                            border-top-right-radius: 0;
                                            border-bottom-right-radius: 0;
                                        }
                                    }
                                }

                                &:nth-child(5) {
                                    grid-column: 1 / 3;
                                    grid-row: 3 / 3;
                                    border-bottom-right-radius: 8px;

                                    .box {
                                        height: 170px;
                                        border-bottom-right-radius: 8px;

                                        span img {
                                            border-bottom-right-radius: 8px;
                                        }
                                    }
                                }

                                &:nth-child(6) {
                                    display: none;

                                    .box {
                                        height: 0;
                                    }
                                }
                            }
                        }

                        @media (max-width: 539.98px) {
                            grid-template-columns: repeat(2, 1fr);
                            grid-template-rows: repeat(3, 170px);
                        }
                    }
                }

                .header {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 1.25rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    text-align: left;
                    margin-bottom: 0.35em;

                }

                .paragraph {
                    font-size: 15.5px;
                    font-family: 'Roboto', sans-serif;
                    color: #535353;
                    font-weight: 300;
                    margin-bottom: 10px;
                    letter-spacing: 0.00538em;
                    line-height: 1.6;

                    .link {
                        color: #d6a44d;
                        text-decoration: none;
                    }
                }

                .list-item-avatar {
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }

                .list-item-text {
                    span {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 300;
                        font-size: 15.5px;
                        letter-spacing: 0.00538em;
                        color: #535353;
                    }
                }
            }
        }
    }
}
